import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import Database from '../../../../Constant/database.json'
import ImageUpload from "../../../ImageUpload";
import ExamQuestionModal from "./ExamQuestionModal";
import { CustomButtonUnSelect } from "../../../CustomButtomUnselect";

const QuestionBlock = [
  "question_number",
  "question",
  "question_description",
  "question_description_type",
  "question_media",
  "question_media_type",
  "option_1",
  "option_2",
  "option_3",
  "option_4",
  "answer_description",
  "correct_answer",
  "answer_media_type",
]

export default function FormComponent(props) {
  const { FetchData, db_slug, webSlug } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)

  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [Time, setTime] = React.useState(FetchData.meta.exam_time)
  const [Details, setDetails] = React.useState(FetchData.meta.exam_details)
  const [Available, setAvaliable] = React.useState(FetchData.meta.available_unavailable)
  const [FeaturedImage, setFeaturedImage] = React.useState(FetchData.meta.featured_image)
  const [ExamType, setExamType] = React.useState(FetchData.meta.exam_type)
  const [ExamSubType, setExamSubType] = React.useState(FetchData.meta.exam_sub_type)
  const [CopyTo, setCopyTo] = React.useState("")
  const [AudioSingle, setAudioSingle] = React.useState(FetchData.meta.media_non_repeat)
  const [ExamCode, setExamCode] = React.useState(FetchData.meta.exam_code)

  const [QuestionsBlock, setQuestionsBlock] = React.useState(true);
  const [QuestionsData, setQuestionsData] = React.useState(JSON.parse(FetchData.meta.exam_question_block_text));
  const [QuestionsEditMode, setQuestionsEditMode] = React.useState(false);
  const [QuestionsEditData, setQuestionsEditData] = React.useState({});
  const [QuestionsEditKey, setQuestionsEditKey] = React.useState();

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const EditInfo = (POSTID, slug, passSlug) => {
    setReload(true)
    console.log(QuestionsData)
    var data = {
      "title": Title,
      "status": "publish",
      "meta": {
        "exam_question_block_text": JSON.stringify(QuestionsData),
        "exam_time": Time,
        "exam_details": Details,
        "available_unavailable": Available,
        "featured_image": FeaturedImage,
        "media_non_repeat": AudioSingle,
        "exam_type": ExamType,
        "exam_sub_type": ExamSubType,
        "exam_code": ExamCode
      }
    }
    if (POSTID === "")
      AdsPOSTApi(data, slug)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Exam Added Successfully")
          console.log(res.data)
          history.push(`${passSlug}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slug, POSTID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const [batchID, setBatchID] = React.useState(FetchData.meta.batch_id)
  const [batchName, setBatchName] = React.useState(FetchData.meta.batch_name)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])

  useEffect(() => {
    if (db_slug == "daily_exam")
      BatchList()
  }, [])
  const BatchList = () => {
    AdsGETApiAuth(
      { per_page: 100, _fields: "id,title,meta.batch_status,meta.batch_start_date,meta.batch_end_date" },
      slugs.db_slug_batch
    )
      .then((res) => {
        setBatchList(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  const location = useLocation()

  return (
    <div>
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Exam Information</center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  />
                </td>
              </tr>

              <tr>
                <td className="col-3">Exam Time (in minutes)</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Time} placeholder="Exam Time"
                    onChange={(event) => { setTime(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Code</td>
                <td className="col-6">
                  <input className="input-common"
                    value={ExamCode} placeholder="Exam Code"
                    onChange={(event) => { setExamCode(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Details</td>
                <td className="col-6">
                  <textarea rows={5} className="input-common"
                    value={Details} placeholder="Exam Details"
                    onChange={(event) => { setDetails(event.target.value) }}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Featured Image</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={FeaturedImage}
                    setImageUrl={setFeaturedImage}
                  />
                </td>
              </tr>
              <tr>
                <td className="col-3">Audio Single</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    <div style={{ display: "inline-block" }}>
                      <CustomButtonUnSelect setData={setAudioSingle} Data={AudioSingle} DataValue={"Single"} />
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Name</td>
                <td className="col-6">
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Available"} />
                  <CustomButton setData={setAvaliable} Data={Available} DataValue={"Unavailable"} />
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Type</td>
                <td className="col-6">
                  <div style={{ display: "inline" }}>
                    {/* {Database.exam_type.map((item, index) => ( */}
                    {/* <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={} Data={ExamType} DataValue={item} />
                      </div> */}
                    {ExamType}
                    {/* ))} */}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-3">Exam Sub Type</td>
                <td className="col-6">
                  <div>
                    <CustomButtonUnSelect setData={setExamSubType} Data={ExamSubType} DataValue={"Free"} />
                    {ExamType == "CBT" &&
                      <div style={{ display: "inline" }}>
                        <>
                          {Database.cbt_type.map((item, index) => (
                            <div style={{ display: "inline-block" }} key={index}>
                              <CustomButtonUnSelect setData={setExamSubType} Data={ExamSubType} DataValue={item} />
                            </div>
                          ))}
                        </>
                      </div>
                    }
                  </div>
                  {/* {Database.exam_type.map((item, index) => ( */}
                  {/* <div style={{ display: "inline-block" }} key={index}>
                        <CustomButton setData={} Data={ExamType} DataValue={item} />
                      </div> */}

                  {/* ))} */}
                </td>
              </tr>

            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo(ID, db_slug, webSlug)
                }}
              >
                Update Exam
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Questions</center></h4>
          <div style={{ display: "inline", justifyContent: "flex-end" }}>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: QuestionsBlock ? "#000000" : "#444444", fontWeight: QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(true)
              }}
            >
              Block
            </div>
            <div style={{ cursor: "pointer", display: "inline-block", margin: "5px", color: !QuestionsBlock ? "#000000" : "#444444", fontWeight: !QuestionsBlock ? "bold" : "normal" }}
              onClick={() => {
                setQuestionsBlock(false)
              }}
            >
              Table
            </div>
          </div>
          {location.pathname.includes("textbook") &&
            <div>
              <div className="row center m-2">
                <div className=" col-3" style={{ padding: "0 10px" }}>
                  <div className="sign-in-button-4"
                    onClick={() => {
                      setQuestionsEditData({
                        "question_number": `${Object.keys(QuestionsData).length + 1}`,
                        "question": "",
                        "question_description": "",
                        "question_description_type": "text",
                        "question_media": "",
                        "question_media_type": "none",
                        "option_1": "",
                        "option_2": "",
                        "option_3": "",
                        "option_4": "",
                        "answer_description": "",
                        "correct_answer": "option 1",
                        "answer_media_type": "text",
                      })
                      setQuestionsEditKey(`item-${Object.keys(QuestionsData).length}`)
                      setQuestionsEditMode(true)
                    }}
                  >
                    Add Question
                  </div>
                </div>
              </div>
            </div>

          }
          {QuestionsBlock ?
            <div>
              {Object.values(QuestionsData).length !== 20 ?
                <div className="row m-4">
                  <div className="col-6">
                    <div className="row">
                      {Object.values(QuestionsData).map((item, index) => {
                        if (index < Object.values(QuestionsData).length / 2)
                          return (
                            <div key={index} className="col-2 p-2 center"
                              style={{
                                margin: "5px",
                                backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                fontWeight: "bold",
                                border: "2px solid black",
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setQuestionsEditData(item)
                                setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                setQuestionsEditMode(true)
                              }}
                            >
                              {item.question_number}
                            </div>
                          )
                      })}
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      {Object.values(QuestionsData).map((item, index) => {
                        if (index >= Object.values(QuestionsData).length / 2)
                          return (
                            <div key={index} className="col-2 p-2 center"
                              style={{
                                margin: "5px",
                                backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                fontWeight: "bold",
                                border: "2px solid black",
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setQuestionsEditData(item)
                                setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                setQuestionsEditMode(true)
                              }}
                            >
                              {item.question_number}
                            </div>
                          )
                      })}
                    </div>
                  </div>
                </div>
                :
                <div>
                  <div className="row m-4">
                    <div className="col-12">
                      <div className="row">
                        {Object.values(QuestionsData).map((item, index) => {
                          return (
                            <div key={index} className="col-lg-1 col-2 p-2 center"
                              style={{
                                margin: "5px",
                                backgroundColor: item.question !== "" ? "#fad4d4" : "#fafad4",
                                fontWeight: "bold",
                                border: "2px solid black",
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                setQuestionsEditData(item)
                                setQuestionsEditKey(Object.keys(QuestionsData)[index])
                                setQuestionsEditMode(true)
                              }}
                            >
                              {item.question_number}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
            :
            <div style={{ overflowX: "scroll" }}>
              <div
                className="row"
                onClick={() => {

                }}
              >
                <div style={{ width: "5%", display: "inline", textAlign: "center" }}>QN</div>
                <div style={{ width: "30%" }}>Question</div>
                <div style={{ width: "15%", }}>Option 1</div>
                <div style={{ width: "15%", }}>Option 2</div>
                <div style={{ width: "15%", }}>Option 3</div>
                <div style={{ width: "15%", }}>Option 4</div>
                <div style={{ width: "5%", }}>Correct</div>
              </div>
              {Object.values(QuestionsData).map((item, index) => (
                <div key={index} style={{ cursor: "pointer", backgroundColor: index % 2 == 0 ? "#fff" : "#eee", padding: "5px 0" }}
                  className="row"
                  onClick={() => {
                    setQuestionsEditData(item)
                    setQuestionsEditKey(Object.keys(QuestionsData)[index])
                    setQuestionsEditMode(true)
                  }}
                >
                  <div style={{ width: "5%", display: "inline", textAlign: "center" }}>{item.question_number}</div>
                  <div style={{ width: "30%" }}>{item.question}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_1 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_2 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_3 : item.answer_media_type}</div>
                  <div style={{ width: "15%", }}>{item.answer_media_type == "text" ? item.option_4 : item.answer_media_type}</div>
                  <div style={{ width: "5%", }}>{item.correct_answer.replace("option ", "")}</div>
                </div>
              ))}
            </div>
          }
        </div>
      </div>

      {/* <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Make a copy to:</center></h4>
          <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Batch Wise Exam"} />
          {db_slug == "daily_exam" &&
            <>
              {Object.keys(QuestionsData).length == 40 &&
                <>
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"All Exam"} />
                  <CustomButton setData={setCopyTo} Data={CopyTo} DataValue={"Demo Exam"} />
                </>
              }
            </>
          }
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  if (CopyTo == "Batch Wise Exam")
                    EditInfo("", slugs.db_slug_daily_exam, slugs.daily_exam_details)
                  else if (CopyTo == "All Exam")
                    EditInfo("", slugs.db_slug_paid_exam, slugs.exam_details)
                  else
                    EditInfo("", slugs.db_slug_demo_exam, slugs.demo_exam_details)
                }}
              >
                Make a copy
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {
        QuestionsEditMode &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setQuestionsEditMode(false)
                    setQuestionsEditData([])
                    setQuestionsEditKey("")
                  }}
                >X</div>
                <ExamQuestionModal
                  oneQuestionData={QuestionsEditData}
                  keyValue={QuestionsEditKey}
                  AllQuestionData={QuestionsData}
                  setAllQuestionData={setQuestionsData}
                  PostExam={EditInfo}
                  setEditQuestion={setQuestionsEditMode}
                />
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}