import React from "react";
import FormComponent from "./Components/Form";
import Database from '../../../Constant/database.json'
import { slugs } from "../../../Constant/slugs";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
export default function NewBook() {
  const location = useLocation()
  var type = location.pathname.slice(slugs.all_books.length) == "" ? "book" : location.pathname.slice(slugs.all_books.length)
  const typeArray = {
    "book": "Book",
    "videos": "Videos",
    "skill-test": "Materi Skill Test",
  }
  const FetchData = {
    "id": "",
    "title": {
      "rendered": ""
    },
    "meta": {
      "exam_question_block_text": [],
      "book_name": "",
      "book_pdf": "",
      "book_type": typeArray[type],
      "book_author": "",
      "book_published_date": "",
      "about_book": "",
      "featured_image": "",
      "book_other_informations": "[]",
      "course_content": "[]"
    }
  }
  return (
    <div>
      <div className="row" style={{ padding: "30px 30px" }}>
        <div className="col-md-8 button">
          <h2>New {typeArray[type]}</h2>
        </div>
        <div className="col-md-4" style={{ textAlign: 'right' }}>
          <a href="/">Dashboard</a> / <a href={`${slugs.all_books}/${type}`}>All {typeArray[type]}</a>
        </div>
      </div>
      <FormComponent
        FetchData={FetchData}
      />
    </div>
  )
}